const theme = {
    config: {
        initialColorModeName: 'light',
        useColorSchemeMediaQuery: true,
    },
    styles: {
        root: {
            transition: 'all 0.25s ease',
        }
    },
    colors: {
        text: '#666',
        secondaryText: '#333',
        background: '#fff',
        primary: '#8954A8',
        appIconBg: '#ffffff',
        modes: {
          dark: {
            text: '#aaa',
            secondaryText: '#fefefe',
            background: '#1c1c1c',
            primary: '#8954A8',
            appIconBg: '#111',
          }
        }
      },
      // Separate from colors
      shadows: {
        appIcon: '0 2px 8px rgba(0,0,0,0.1)',
        modes: {
            dark: {
                appIcon: '0 2px 8px rgba(255,255,255,0.1)',
            }
        }
      },
      borders: {
        appIcon: 'none',
        modes: {
            dark: {
                appIcon: '1px solid rgba(255,255,255,0.1)',
            }
        }
      },
    fonts: {
        body: "Inter, system-ui, -apple-system, sans-serif",
        heading: "Inter, system-ui, -apple-system, sans-serif",
    },
    fontSizes: [
        "1rem", "1.2rem", "1.5rem", "2rem", "3rem", "3.25rem", "3.5rem", "4rem", "5rem"
    ],
    lineHeights: {
        body: "1.7rem",
        heading: "1.5rem",
    }
}

export default theme